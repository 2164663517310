(function () {
  const navItem3 = document.querySelector('.footer-grid__item:nth-child(3)')
  const navItem4 = document.querySelector('.footer-grid__item:nth-child(4)')
  const $footerRightLogo = document.querySelector('.footer__right-logo');
  const $nav = document.querySelector('.footer__nav');
  const $topMenu = $nav.firstElementChild;
  const menuItemWithChildren = $nav.querySelectorAll('.menu-item-has-children');

  if (window.innerWidth <= 991) {
    $footerRightLogo.style.width = 'auto';
  }

  window.addEventListener('resize', function () {
    if (window.innerWidth <= 991) {
      $footerRightLogo.style.width = 'auto';
    }
  })

  if (window.innerWidth <= 991) {
    menuItemWithChildren.forEach(item => {
      const link = item.firstElementChild;
      link.addEventListener('click', function (e) {
        if (!item.classList.contains('active')) {
          e.preventDefault();
          menuItemWithChildren.forEach(item1 => {
            item1.classList.remove('active');
          })
          item.classList.add('active')
        }
      })
    })
  }

  if ($nav && window.innerWidth > 991) {
    calculateNavWidth();
    menuItemWithChildren.forEach(item => {
      item.addEventListener('mouseover', function (e) {
        // e.preventDefault()
        // menuItemWithChildren.forEach(m => m.classList.remove('opened'))
        item.classList.add('active');
        item.querySelector('.sub-menu').classList.add('opened');
        calculateNavWidth();
      })
      item.addEventListener('mouseout', function (e) {
        item.classList.remove('active')
        item.querySelector('.sub-menu').classList.remove('opened');
        calculateNavWidth();
        // e.preventDefault()
        // menuItemWithChildren.forEach(m => m.classList.remove('opened'))
        // item.querySelector('.sub-menu').classList.add('opened');
        // calculateNavWidth();
      })
    })
  }

  function calculateNavWidth() {
    const $submenuOpened = $nav.querySelectorAll('.sub-menu.opened');
    const initialWidth = $topMenu.clientWidth;
    const menuWidth = Array.prototype.slice.call($submenuOpened).reduce(
        (accumulator, menu) => accumulator + menu.clientWidth,
        initialWidth,
    );
    const logoWidth = navItem3.clientWidth + navItem4.clientWidth - menuWidth;
    $footerRightLogo.style.width = (logoWidth < 0 ? 0 : (logoWidth < 242) ? logoWidth : 242) + 'px';
  }
})()