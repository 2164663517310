document.addEventListener("DOMContentLoaded", function() {  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./target-blank.js');
  require('./hero-video.js');
  require('./slider.js');
  require('./footer');
  require('./hero-title');
  require('./menu');
});

// remove preloader
window.addEventListener('load', function () {
  $('.preloader').fadeOut();
  document.querySelector('html').classList.add('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 500,
    delay: 200,
    // easing: 'linear',
    // easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    easing: 'ease-in-out-quart',

  });

  equalizeHeight('.quote-card__image')

  window.addEventListener('resize', function () {
    equalizeHeight('.quote-card__image')
  })

});

function equalizeHeight (className) {
  const collection = document.querySelectorAll(className);
  let titleMaxHeight = 0
  collection.forEach(el => {
        titleMaxHeight = Math.max(titleMaxHeight, el.offsetHeight);
      }
  )
  collection.forEach(el => el.style.minHeight = titleMaxHeight + 'px')
}

