(function () {

  calculateTitleHeight()
  window.addEventListener('resize', function () {
    calculateTitleHeight()
  })

  function calculateTitleHeight () {
    const heroTitle = document.querySelector('.hero__title');
    if (heroTitle) {
      const titleHeight = heroTitle.offsetHeight - 20;
      const text = heroTitle.querySelector('div');
      if (text) {
        text.style.minWidth = titleHeight + 'px'
      }
    }
  }
})()