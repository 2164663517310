(function () {

  const $header = document.querySelector('.header');
  const $menu = document.querySelector('.header__main');
  const $hamburger = document.querySelector('.burger');
  let isMenuOpened = false;
  $hamburger.addEventListener('click', function () {
    this.classList.toggle('opened')
    $menu.classList.toggle('opened');
    $header.classList.toggle('menu-opened');
    isMenuOpened = !isMenuOpened;

    if (isMenuOpened) {
      bodyScrollLock.disableBodyScroll($menu);
    } else {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  });

  window.addEventListener('resize', debounce(function () {
    if (isMenuOpened && window.innerWidth >= 991) {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  }))

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  const menuItemsHasChildren = document.querySelectorAll('.main-nav .menu > .menu-item-has-children');
  menuItemsHasChildren.forEach(item => {
    const link = item.firstElementChild;
    if (window.innerWidth <= 991) {
      link.addEventListener('click', function (e) {
        if (!item.classList.contains('submenu-opened')) {
          e.preventDefault();
          menuItemsHasChildren.forEach(item1 => {
            item1.classList.remove('submenu-opened');
          })
          item.classList.add('submenu-opened')
        }
      })
    }
  })

  window.addEventListener('hashchange', smoothScroll)

  function smoothScroll() {
    let myHash = location.hash.substr(1);
    location.hash = '';
    if (myHash[1] != undefined && document.getElementById(myHash)) {
      if (isMenuOpened) {
        const event = new Event('click')
        $hamburger.dispatchEvent(event)
      }
      let hashTop = getCoords(document.getElementById(myHash)).top - 300;
      window.scroll({
        top: hashTop,
        behavior: 'smooth'
      });
    }
  }

  function getCoords(elem) { // кроме IE8-
    var box = elem.getBoundingClientRect();
    return {
      top: box.top + pageYOffset,
      left: box.left + pageXOffset
    };
  }
})();
